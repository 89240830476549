<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="150px" :model="formLabelAlign">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="名称："
              prop="name"
              :rules="[{ required: true, message: '请输入名称' }]"
            >
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="检查周期：">
              <el-select
                v-model="formLabelAlign.check_times"
                placeholder="请选择检查周期"
                class="form-inp"
              >
                <el-option
                  v-for="item in check_times"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="考核分数" prop="score">
              <el-input
                type="number"
                placeholder="考核分数"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.score"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="部门：" prop="department_id">
              <BepartmentMulti ref="BepartmentMulti" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="addition">
              <el-button
                @click="on_addition()"
                icon="el-icon-plus"
                type="success"
                >添加新节点</el-button
              >
            </div>
            <div v-for="(el, index) in base" :key="index" class="inputsty">
              <el-input placeholder="请输入内容" v-model="el.content" clearable>
                <template slot="append">
                  <el-button
                    @click="on_deletenode(index)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </template>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { evaluationsaveinfo, evaluationsave } from "../../assets/request/api";

import BepartmentMulti from "../../components/Bepartment_multi.vue";

export default {
  name: "system_redact",
  components: {
    BepartmentMulti,
  },
  data() {
    return {
      //要修改的id
      id: "",
      type_name: "确认",
      check_times: [],
      base: [],
      formLabelAlign: {
        name: "",
        score: 0,
        department_id: "",
        check_times: "",
      },
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    //添加节点
    on_addition() {
      this.base.push({
        content: "",
      });
    },

    on_deletenode(index) {
      this.base.splice(index, 1);
    },

    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      evaluationsaveinfo(froms).then((res) => {
        let { info, check_times } = res.data;
        this.check_times = check_times;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, department_id, check_times, score, content } = info;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.check_times = check_times;
          formLabelAlign.score = score;
          let base = [];
          content.map((item) => {
            base.push({
              content: item,
            });
          });

          this.base = base;
          let item = department_id.split(",");
          this.$refs.BepartmentMulti.way_show(item);
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          let department_id = this.$refs.BepartmentMulti.way_pitch();
          let { base } = this;
          if (!department_id) {
            this.$message({
              message: "请选择部门",
              type: "warning",
            });
          }
          formLabelAlign.department_id = department_id;
          let content = [];
          base.map((item) => content.push(item.content));

          formLabelAlign.content = content;

          evaluationsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
  height: 32px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width: 550px;
  margin-bottom: 10px;
}
.execute {
  display: flex;
}
.execute /deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.qui {
  height: 400px;
  margin-bottom: 40px;
}
.addition {
  margin: 10px 0;
  text-align: center;
}

.inputsty {
  width: 90%;
  margin: 0 auto;
  padding: 10px 0;
}
</style>
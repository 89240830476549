<template>
  <div>
    <el-tree
      :data="bepartment_Data"
      show-checkbox
      :check-strictly="false"
      ref="tree"
      :props="{
        children: 'items',
        label: 'name',
      }"
      node-key="id"
      @check-change="handleCheckChange"
    ></el-tree>

  </div>
</template>

<script>
import { departmentindex } from "../assets/request/api";
export default {
  name: "Bepartment",
  model: {
    prop: "department_id",
    event: "changeData",
  },
  props: ["department_id"],
  data() {
    return {
      bepartment_Data: [],
    };
  },
  created() {
    this.way_departmentindex();
  },
  methods: {
    //部门信息
    way_departmentindex() {
      departmentindex().then((res) => {
        let { data, enable_list } = res.data;
        this.bepartment_Data = data;
      });
    },
    //获取选中的
    way_pitch() {
      return this.$refs.tree.getCheckedKeys().join(","); //通过 key 获取 选中的
    },
    handleCheckChange(data, checked, indeterminate){
      this.$emit("changeData");
    },
    //选中的回显
    way_show(department_str) {
      this.$refs.tree.setCheckedKeys(department_str);
    },
  },
};
</script>

<style lang="less" scoped>
</style>